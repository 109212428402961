import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  slideIndex :number= 0;
  constructor() { }

  ngOnInit(): void {
    setInterval(()=>this.showSlides(), 3000);
  }

  showSlides() {
 
    var slides :any = document.getElementsByClassName("imgbox1");
    slides[this.slideIndex].classList.remove('active');
  
    this.slideIndex = (this.slideIndex + 1) % slides.length;

    slides[this.slideIndex].classList.add('active');
     
    // setTimeout(()=>this.showSlides(), 3000); // Change image every 3 seconds
  }

}
