<section class="contact" id="contact">
    <h2 class="heading" data-center-center="opacity:1;top:0px;" data-0-bottom="opacity:0;top:-100px;">Contact Us</h2>

    <div class="contentbox redbg">
      <form [formGroup]="contactForm" id="form" (ngSubmit)="send_mail()">
        <div class="inputbox">
          <input type=text name="custname" placeholder="Full Name" required formControlName="name">
        </div>
        <div class="inputbox">
          <input type="tel" name="custtel" placeholder="Mobile Number" required formControlName="mobile">
        </div>
        <div class="inputbox">
          <input type=email name="custemail" placeholder="Email" required formControlName="email">
        </div>
        <div class="inputbox">
          <textarea type="text" name="custmessage" placeholder="Type your message Here" formControlName="message"></textarea>
        </div>
        <input type="hidden" name="_captcha" value="false">  
        <input type="hidden" name="_template" value="basic">
        <div class="inputbox">
          <input type="submit" >
        </div>
            
            
        </form>

        
        
    </div> 

</section>