import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {

  slideIndex = 0;
  activeSlide=0;
  constructor() { }

  ngOnInit(): void {
    setInterval(()=>this.moveSlides(),3000);
  }

  moveSlides(){
    const slides = document.getElementsByClassName('carousel-item');
    slides[this.activeSlide].classList.remove('active');
  
    this.activeSlide = (this.activeSlide + 1) % slides.length;

    slides[this.activeSlide].classList.add('active');

  }
 

}
