<section class="product" id="products">
    <h2 class="heading" data-center-center="opacity:1;top:0px;" data-0-bottom="opacity:0;top:-100px;">Our Products
    </h2>
    <p class="text" data-center-center="opacity:1;bottom:0px;" data-0-bottom="opacity:0;bottom:-400px;">
        All of our Products offerings are sourced from genuine  suppliers who have proved their value on a global level time &
        again. With SST Tools , You will find products that are highly durable , consistant & cost effective in Nature.
    </p>
    <div class="imgbox">
        <img src="assets/images/ARNO.png" class="button"  id="myBtn" href="#arnoModal" data-center-center="opacity:1;top:0px;" data-0-bottom="opacity:0;top:100px;"
        (click)="addDisplayStyle('arnoModal')">
        
        <div id="arnoModal" class="modal">

            <!-- Modal content -->
            <div class="modal-content">
                <span class="close" (click)="hideModal('arnoModal')">&times;</span>
              <div class="modal-header">
              <img id="arno" src="assets/images/ARNO.png" alt="ARNO">

                
              </div>
              <div class="modal-body">
                <p>ARNO Provide German Premium Quality Industrial tooling &
                    Machining Products for value Added Solution ARNO HeadQuater in
                    Germany. Most Popular Products are <b>Shark Drill2 , Shark drill , SA
                    Grooving , Parting & Grooving turning , Long Turning , Solid
                    carbide Mills</b>. ARNO Have large Variety of Index able Inserts in the
                    field of Industrial precision Tooling .</p>
                <p></p>
              </div>
              <div class="modal-footer">
                <a class="know_more" href="https://www.arno.de/en/" target="_blank" rel="noopener noreferrer">Know More <i class="fas fa-info-circle"></i></a>
              </div>
            </div>
          
          </div>


        <img src="assets/images/CARMEX.jpeg" href="#carmexModal" class="button" id="myBtn" data-center-center="opacity:1;top:0px;" data-0-bottom="opacity:0;top:200px;"
        (click)="addDisplayStyle('carmexModal')">
        <div id="carmexModal" class="modal">

            <!-- Modal content -->
            <div class="modal-content">
                <span class="close" (click)="hideModal('carmexModal')">&times;</span>
              <div class="modal-header">
              <img id="arno" src="assets/images/CARMEX.jpeg" alt="CARMEX">

                
              </div>
              <div class="modal-body">
                <p>Carmex is cutting Tools manufacturer in Threading & Grooving Tools
                . Carmex Headquarter & Company Located in Israel .Carmex is World
                Leader in Production of Threading Tools for Turning & Milling.In
                addition to threading , Carmex Produce Grooving Tools , Mini
                Chamfer Mills & the Tinny Tools line of small boring bars for
                threading ,turning , Grooving small parts.</p>
                <p>You're welcome to test Carmex TOOLS anytime, anywhere , against
                    anyone.</p>
              </div>
              <div class="modal-footer">
                <a class="know_more" href="https://carmex.com/" target="_blank" rel="noopener noreferrer">Know More <i class="fas fa-info-circle"></i></a>
              </div>
            </div>
          
          </div>
        <img src="assets/images/UFS.png" href="#ufsModal" class="button" id="myBtn" data-center-center="opacity:1;top:0px;" data-0-bottom="opacity:0;top:300px;"
        (click)="addDisplayStyle('ufsModal')">
        <div id="ufsModal" class="modal">

            <!-- Modal content -->
            <div class="modal-content">
                <span class="close" (click)="hideModal('ufsModal')">&times;</span>
              <div class="modal-header">
              <img id="arno" src="assets/images/UFS.png" alt="UFS">

                
              </div>
              <div class="modal-body">
                <p>UFS Has 70 Years of Experience in Thearding Tools Sector.UFS
                    Headquarter in ITALY.Company Has providing service in 40 Countries
                    Throughout the world.UFS Has is Large Finish Product Warehouse &
                    have over 60000 Standard products.More than 70 years of expertise in
                    cutting tool manufacturing , combined with powerful R&D resources,
                    place UFS Running to the Top of Technical innovation of Cutting
                    Tools.</p>
              </div>
              <div class="modal-footer">
                <a class="know_more" href="https://www.ufs.it/it/" target="_blank" rel="noopener noreferrer">Know  More <i class="fas fa-info-circle"></i></a>
              </div>
            </div>
          
          </div>
        <img src="assets/images/PALBIT LOGO.jpg" href="#palbitModal" class="button" id="myBtn" data-center-center="opacity:1;top:0px;" data-0-bottom="opacity:0;top:400px;"
        (click)="addDisplayStyle('palbitModal')">
          
        <div id="palbitModal" class="modal">

            <!-- Modal content -->
            <div class="modal-content">
                <span class="close" (click)="hideModal('palbitModal')">&times;</span>
              <div class="modal-header">
              <img id="arno"src="assets/images/PALBIT LOGO.jpg" alt="PALBIT">

                
              </div>
              <div class="modal-body">
                <p>Palbit Produced Hardmetal Cutting Tools from more than 100
                    Years. palbit is a symbol of high Performance tools.It has wide
                    Range in Indexable tool.Palbit Headquatered In Portugal.</p>
              </div>
              <div class="modal-footer">
                <a class="know_more" href="https://www.palbit.pt/" target="_blank" rel="noopener noreferrer">Know  More <i class="fas fa-info-circle"></i></a>
              </div>
            </div>
          
          </div>
       
        <a href="https://www.noga.com/" target="_blank" rel="noopener noreferrer"><img src="assets/images/noga.png" data-center-center="opacity:1;top:0px;" data-0-bottom="opacity:0;top:400px;"></a>
        
        
        <img src="assets/images/boehlerit.jpg" href="#boehleritModal" class="button" id="myBtn" data-center-center="opacity:1;top:0px;" data-0-bottom="opacity:0;top:400px;"
        (click)="addDisplayStyle('boehleritModal')">
        <div id="boehleritModal" class="modal">

            <!-- Modal content -->
            <div class="modal-content">
                <span class="close" (click)="hideModal('boehleritModal')">&times;</span>
              <div class="modal-header">
              <img id="arno" src="assets/images/boehlerit.jpg" alt="Boehlerit">

                
              </div>
              <div class="modal-body">
                <p>Boehlerit was Established in 1942 for Carbide Production in
                    Germany. Carbide & tools from Boehlerit are the pace setters for new
                    standards in machining of Metals , Woods , Synthetic & Composite
                    Materials.The Compheresive Boehlerit product range includes highly specialized tools for cranckshaft machining , Tube & pipe machining ,
                    Bar Peeling , Milling & Heavy duty machining operation in steel
                    industries. Boehlerit achieve a world first & unique positioning
                    globally with its NANO CVD Bonding layer through to the hardest
                    diamond coatings.</p>
              </div>
              <div class="modal-footer">
                <a class="know_more" href="https://www.boehlerit.com/" target="_blank" rel="noopener noreferrer">Know  More <i class="fas fa-info-circle"></i></a>
              </div>
            </div>
          
          </div>
       
        <img src="assets/images/mimatic.png" href="#mimaticModal" class="button" id="dark" data-center-center="opacity:1;top:0px;" data-0-bottom="opacity:0;top:400px;"
        (click)="addDisplayStyle('mimaticModal')">
        <div id="mimaticModal" class="modal">

            <!-- Modal content -->
            <div class="modal-content">
                <span class="close" (click)="hideModal('mimaticModal')">&times;</span>
              <div class="modal-header">
              <img id="arno" src="assets/images/mimatic.png" alt="Boehlerit">

                
              </div>
              <div class="modal-body">
                <p>Mimatic is acknowledged worldwide for its compliance with strict
                quality requirements in the context of integrated system solution for
                machining segments.Mimatic is developing CNC Precision standard &
                special tools for the automotive , Mechanical Engineering , Aircraft
                industries since 1974 in Germany.Products from Mimatic tool Systems :
                Driven Toolholders for CNC lathes & machining centres.<br>
                Cutting Tools<br>
                Clamping Systems</p>
              </div>
              <div class="modal-footer">
                <a class="know_more" href="https://www.boehlerit.com/" target="_blank" rel="noopener noreferrer">Know  More <i class="fas fa-info-circle"></i></a>
              </div>
            </div>
          
          </div>
       
        <img src="assets/images/lessmann.png" href="#lessmannModal" class="button" id="myBtn" data-center-bottom="opacity:1;top:0px;" data-0-bottom="opacity:0;top:400px;"
        (click)="addDisplayStyle('lessmannModal')">
    
        <div id="lessmannModal" class="modal">

            <!-- Modal content -->
            <div class="modal-content">
                <span class="close" (click)="hideModal('lessmannModal')">&times;</span>
              <div class="modal-header">
              <img id="arno" src="assets/images/lessmann.png" alt="Boehlerit">

                
              </div>
              <div class="modal-body">
                <p>Lessmann production site in Oettingen , Germany &
                    Production Surface Tool “Made in Germany” Since
                    1948.They Produce Round Brush , Cup Brush , Deburring
                    Brushes & Many other Standard as well as Customized
                    Brushes.</p>
              </div>
              <div class="modal-footer">
                <a class="know_more" href="https://www.lessmann.com/en" target="_blank" rel="noopener noreferrer">Know  More <i class="fas fa-info-circle"></i></a>
              </div>
            </div>
          
          </div>
       
    </div>
</section>
