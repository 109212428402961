import { Component, OnInit } from '@angular/core';
import emailjs from 'emailjs-com';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {

  emailService = 'service_939lyij'; // Get this from your email service provider
 templateId = 'template_h04esos'; // Get this from your email service provider
 userId = '4dYczRzPONd7RA_0R'; 
 contactForm !: FormGroup;
  constructor(
    private toastr: ToastrService,
    private _fb : FormBuilder
  ) { }

  ngOnInit(): void {

    this.contactForm = this._fb.group({
      name: ['',[Validators.required]],
      mobile : ['',[Validators.required]],
      email: ['',[Validators.required]],
      message : ['',[Validators.required]]
    })
  }

  send_mail(){

    let body = this.contactForm.controls['message'].value + '\n Mobile no : ' + this.contactForm.controls['mobile'].value;
    emailjs.send(this.emailService, this.templateId, {
      to: 'shreetools2004@gmail.com',
      from: this.contactForm.controls['email'].value,
      subject: 'New contact email',
      message: body,
      name : this.contactForm.controls['name'].value
    }, this.userId)
      .then((response) => {
        console.log("here");
        
        this.toastr.success('Email sent successfully');
        this.contactForm.reset();
      })
      .catch((error) => {
        this.toastr.error('Email sending failed',"Oops");
      });
  }

}
