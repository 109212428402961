import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  addDisplayStyle(id:string){
    let ele = document.getElementById(id);
    if (ele) {
        ele.style.display = 'block';      
    }
  }

  hideModal(id:string){
    let ele = document.getElementById(id);
    if(ele)
    ele.style.display = 'none';    
  }

}
