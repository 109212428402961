<div class="banner" id="home">
    <nav id="navbar">
        <div id="brand">
        <a href="#" class="logo" id="logo"><img src="assets/images/SSTT-1.png" height="60px" width="60px"></a>
        <span id="logohead">SHREE SHYAM TECHNO TOOLS</span>
    </div>
        <div class="menu-wrap">
            <img src="assets/images/toggle.png" onclick="showNav()" id="show">
            <img src="assets/images/128-1281855_buy-online-transparent-white-x-png.png" id="hide" onclick="hideNav()">
            <div id="hamburger"><ul>
            <li><a href="#home" class="active">Home</a></li>
            <li><a href="#about">About Us</a></li>
            <li><a href="#products">Our Products</a></li>
            <li><a href="#contact">Contact Us</a></li>
        </ul></div>
            <div class="menu">
                        <ul>
                            <li><a href="#home" class="active">Home</a></li>
                            <li><a href="#about">About Us</a></li>
                            <li><a href="#products">Our Products</a></li>
                            <li><a href="#contact">Contact Us</a></li>
                        </ul>
            </div>
        </div>  
    </nav>
</div>