<div class="vision" id="vision">
    <div class="contentbox" data-center-center="opacity:1;left:0px;" data-0-bottom="opacity:0;left:-400px;">
        <h2 class="heading">
            Why SSTT Tools?
        </h2>
        <p class="text">Shree Shyam Techno Tools shape represents a breakthrough in tooling , being more efficient , cost effective & precise than conventional tooling devices.These are the adjectives we like to associate with our work and we belive this name to be more indicative of our Philosophy and more representative of our ongoing effort towards innovative solution.</p>
    </div>
    <div class="tags">
    <div class="imgbox" data-center-center="opacity:1;left:0px;" data-0-bottom="opacity:0;left:400px;"><img src="assets/images/shield.png" alt="">
    </div>
    <div class="imgbox" data-center-center="opacity:1;left:0px;" data-0-bottom="opacity:0;left:400px;"><img src="assets/images/high-quality.png" alt="">
    </div>
  </div>
</div>
