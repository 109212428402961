<section class="about " id="about">
        <div class="contentbox">
            <h2 class="heading" data-100-top="opacity:1;left:0px;" data-0-bottom="opacity:0; left:100px;">About Us</h2>
            <p class="text" data-center-center="opacity:1;right:0px;" data-0-bottom="opacity:0; right:100px;">Shree Shyam Techno Tools is a company in the field of Cutting Tools based at Gurugram, India.We are leading Trader , Distributor , Importer of Turning Insert , SC Driils , Carbide End Mills , Bandsaw Blades and Much More. We aim to be your partner in offering One Stop Solutions for all Your Machine Problems. We Firmly believe in the concept of Quality which has been our Propelling Factor. We adhere & Promote Only Quality Products. FeedBack from the Customer is the key to identity the need for a New Product or Technology.
            </p>
            <h3 data-350-top="opacity:1;left:0px;" data-0-bottom="opacity:0; left:100px;">Our Vision
            </h3>
            <p class="text" data-400-top="opacity:1;right:0px;" data-0-bottom="opacity:0; right:100px;">Our Vision is to be Recognized By our Customers as a Dedicated Partner , Offering , Complete solutions for Metal Cutting.
            </p>
            <p class="text" data-400-top="opacity:1;right:0px;" data-0-bottom="opacity:0; right:100px;">Shree Shyam Techno Tools execute this vision by having a personal commitment to maximizing customer’s competitiveness by Providing Solution Based on high Efficiency Products & associated services.
            </p>
            
        </div>
        <div class="img-box">
            <div class="imgbox1 active">
                <img src="assets/images/Tap 2.jpg">
            </div>
            <div class="imgbox1">
                <img src="assets/images/0003.jpg" >
            </div>
            <div class="imgbox1">
                <img src="assets/images/0001.jpg">
            </div>
      
    </div>
</section>