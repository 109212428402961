import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './core-component/header/header.component';
import { CarouselComponent } from './modules/carousel/carousel.component';
import { FooterComponent } from './core-component/footer/footer.component';
import { AboutComponent } from './modules/about/about.component';
import { VisionComponent } from './modules/vision/vision.component';
import { ProductsComponent } from './modules/products/products.component';
import { ContactsComponent } from './modules/contacts/contacts.component'; 
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { httpInterceptor } from 'src/shared/interceptors/http-interceptor.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    CarouselComponent,
    FooterComponent,
    AboutComponent,
    VisionComponent,
    ProductsComponent,
    ContactsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    // BrowserAnimationsModule,
    ToastrModule.forRoot()
  ],
  providers: [{provide: HTTP_INTERCEPTORS, useClass: httpInterceptor, multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule { }
