<app-header></app-header>
<app-carousel></app-carousel>
<app-about></app-about>
<app-vision></app-vision>
<app-products></app-products>
<app-contacts></app-contacts>

<div class="loader">
	<div class="cube">
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
	</div>
</div>

<app-footer></app-footer>